<template>
  <div class="handle-box">
    <el-input v-model="query.studentId" placeholder="Student No." class="handle-input mr10"
      :clearable="true"></el-input>
    <el-input v-model="query.studentName" placeholder="Student Name" class="handle-input mr10"
      :clearable="true"></el-input>
    <div class="upload-panel">
      <el-button type="primary" class="mr10" @click="handleAddItem(-1)">
        New Grade Line<el-icon>
          <plus />
        </el-icon>
      </el-button>
      <el-upload ref="upload" class="upload-container" :action="getUploadUrl()" :on-success="fileUploadSuccess"
        :limit="1" :on-exceed="handleExceed" :headers="headers">
        <el-button type="primary">Import grades</el-button>
      </el-upload>
      <el-button type="primary" @click="exportTemplateFromReport">
        Export grades template
      </el-button>
    </div>
  </div>
  <el-table :data="filterRecords()" border class="table" ref="multipleTable" header-cell-class-name="table-header"
    @sort-change="changeSort" :default-sort="{ prop: 'CreatedTime', order: 'ascending' }">
    <el-table-column prop="studentId" label="Student No." :width="this.$widthRatio * 132 + 'px'">
      <template v-slot="{ row }">
        <el-input v-if="row.isNew" v-model="row.studentId" size="small" :clearable="true"
          @change="loadStudentData(row)"></el-input>
        {{ row.isNew ? "" : row.studentId }}
      </template>
    </el-table-column>
    <el-table-column prop="studentName" label="Student Name"></el-table-column>
    <el-table-column label="Foundation" :width="this.$widthRatio * 140 + 'px'">
      <template #header> Foundation({{ this.reportData.foundationRawScore }}) </template>
      <template v-slot="{ row }">
        <el-input-number v-model="row.foundation" size="small" :precision="getPrecision()" :step="1" :min="0"
          :max="reportData.foundationRawScore" :controls="false" style="width: 60px"></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="Development" :width="this.$widthRatio * 150 + 'px'">
      <template #header>
        Development({{ this.reportData.developmentRawScore }})
      </template>
      <template v-slot="{ row }">
        <el-input-number v-model="row.development" size="small" :precision="getPrecision()" :step="1" :min="0"
          :max="reportData.developmentRawScore" :controls="false" style="width: 60px"></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="Enrichment" :width="this.$widthRatio * 140 + 'px'">
      <template #header> Enrichment({{ this.reportData.enrichmentRawScore }}) </template>
      <template v-slot="{ row }">
        <el-input-number v-model="row.enrichment" size="small" :precision="getPrecision()" :step="1" :min="0"
          :max="reportData.enrichmentRawScore" :controls="false" style="width: 60px"></el-input-number>
      </template>
    </el-table-column>
    <el-table-column prop="total" label="Total" :width="this.$widthRatio * 80 + 'px'">
      <template v-slot="{ row }">
        {{ this.getTotal(row) }}
      </template>
    </el-table-column>
    <el-table-column label="Comments" :width="this.$widthRatio * 200 + 'px'">
      <template v-slot="{ row }">
        <el-input type="textarea" v-model="row.comment" maxlength="2000" show-word-limit></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="updatedTime" :formatter="$tableCellFormatter.formatDate" label="Updated Time"
      :width="this.$widthRatio * 100 + 'px'"></el-table-column>
    <el-table-column label="Operation" :width="this.$widthRatio * 80 + 'px'" align="center">
      <template #default="scope">
        <el-button v-if="false" @click="handleAddItem(scope.$index, scope.row)">
          <el-icon>
            <plus />
          </el-icon>
        </el-button>
        <el-button @click="handleDeleteItem(scope.$index, scope.row)">
          <el-icon>
            <delete />
          </el-icon>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { getData, downloadFile } from "../../service/api";

export default {
  name: "manageTestRecords",
  props: {
    reportData: Object,
    recordList: Array,
  },
  data() {
    return {
      controllerUrl: "/testReport",
      query: {
        reportId: null,
        studentId: null,
        studentName: null,
        orderBy: "StudentId",
        orderDirection: "ASC",
      },
      headers: { authorization: `Bearer ${this.$user.getAccessToken()}` },
      tableData: [],
    };
  },
  watch: {
    recordList(newValue) {
      console.log("watch.recordList", newValue);
      if (newValue) {
        if (newValue !== this.tableData) {
          this.initAndLoadData(this.reportData, newValue);
        }
      }
    },
  },
  created() {
    this.initAndLoadData(this.reportData, this.recordList);
  },
  methods: {
    initAndLoadData(reportData, recordList) {
      console.log("initAndLoadData", reportData, recordList);
      this.sortListByStudentName(recordList);
      this.query.reportId = reportData.reportId;
      this.tableData = this.reconstructData(recordList);
    },
    importRecords() {
      if (this.$refs.upload.uploadFiles.length <= 0) {
        this.$message.error("Please select a file to go on!");
        return false;
      }
      this.$refs.upload.submit();
    },
    exportTemplateFromReport() {
      if (this.query.reportId) {
        downloadFile(
          "GET",
          `${this.controllerUrl}/exportTemplateFromReportRecords/${this.query.reportId}`
        ).catch((err) => {
          this.$message.error(
            "Download report-records template failed, error message: " + err
          );
        });
      } else if (this.reportData.roomId) {
        downloadFile(
          "GET",
          `${this.controllerUrl}/exportTemplateFromClassRoom/${this.reportData.roomId}`
        ).catch((err) => {
          this.$message.error(
            "Download report-records template failed, error message: " + err
          );
        });
      }
    },
    fileUploadSuccess(response) {
      console.log(response);
      this.$refs.upload.clearFiles();

      if (response.result) {
        let list = response.result;
        if (this.tableData != null && this.tableData.length > 0) {
          let recordList = this.tableData;
          for (let i = 0; i < list.length; i++) {
            let record = list[i];
            let index = recordList.findIndex((element) => {
              return element.studentId === record.studentId;
            });
            if (index >= 0) {
              recordList[index] = record;
            } else {
              recordList.push(record);
            }
          }
        } else {
          this.tableData = list;
        }

        this.$message({
          message: "Import students' marks successfully, but not save to the database",
          type: "success",
        });
      } else {
        this.$message.error(response.message);
      }
    },
    handleExceed(files) {
      this.$refs.upload.clearFiles();
      this.$refs.upload.handleStart(files[0]);
    },
    getUploadUrl() {
      return `${this.$appSetting.getAPIRoot()}${this.controllerUrl}/importRecords/${this.reportData.reportId || ""
        }`;
    },
    loadStudentData(row) {
      console.log("loadStudentData", row);
      if (!row.studentId) return;
      if (this.tableData && this.tableData.length > 0) {
        if (
          this.tableData.findIndex((t) => t !== row && t.studentId === row.studentId) >= 0
        ) {
          this.$message.warning(`The student ${row.studentId} is already exist.`);
          row.studentId = "";
          row.isNew = true;
          return;
        }
      }
      if (row.studentId && row.studentId.length === 8) {
        getData(`/student/${row.studentId}`, null)
          .then((res) => {
            if (res.result && res.code === "200") {
              row.isNew = false;
              row.studentName = res.result.username;
            } else {
              this.$message.error(
                "Fetch student data failed, error message: " + res.message
              );
            }
          })
          .catch(() => { });
      }
    },
    filterRecords() {
      let recordList = this.tableData;
      recordList = recordList.filter((row) => {
        return (
          (!this.query.studentId || this.query.studentId === row.studentId) &&
          (!this.query.studentName ||
            row.studentName.toLowerCase().includes(this.query.studentName.toLowerCase()))
        );
      });

      return recordList;
    },
    reconstructData(recordList) {
      if (recordList && recordList.length > 0) {
        for (let i = 0; i < recordList.length; i++) {
          recordList[i].isNew = false;
        }
      }

      return recordList;
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
    },
    handleAddItem(index) {
      this.tableData.splice(index + 1, 0, {
        isNew: true,
        foundation: 0,
        development: 0,
        enrichment: 0,
        foundationRawScore: 0,
        developmentRawScore: 0,
        enrichmentRawScore: 0,
        bonus: 0,
        total: 0,
      });
    },
    handleDeleteItem(index, row) {
      let pos = this.tableData.findIndex((e) => e.studentId === row.studentId);
      if (pos >= 0) this.tableData.splice(pos, 1);
    },
    handleMoveUpItem(index) {
      if (index <= 0) return;
      let delItems = this.tableData.splice(index, 1);
      this.tableData.splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownItem(index) {
      if (index >= this.tableData.length - 1) return;
      let delItems = this.tableData.splice(index, 1);
      this.tableData.splice(index + 1, 0, delItems[0]);
    },
    sortListByStudentName(recordList) {
      if (recordList && recordList.length) {
        recordList.sort((a, b) => {
          if (a.studentName < b.studentName) {
            return -1;
          }
          if (a.studentName > b.studentName) {
            return 1;
          }
          return 0;
        });
      }
    },
    getTotal(row) {
      row.total =
        row.foundation || row.development || row.enrichment
          ? (row.foundation || 0) + (row.development || 0) + (row.enrichment || 0)
          : null;

      return row.total ? row.total.toFixed(this.getPrecision()) : null;
    },
    getPrecision() {
      return 1;
    },
  },
};
</script>

<style scoped>
.upload-panel {
  float: right;
}

.upload-panel .upload-container {
  float: left;
  margin-right: 10px;
}

.upload-panel:after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 1.17rem;
  margin-top: 30px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.el-table td,
.el-table th {
  padding: 12px;
}

.el-table .cell {
  padding-left: 0;
  padding-right: 0;
}

.el-input-number--small {
  width: 100%;
}
</style>
<style>
.upload-panel .el-upload--text {
  width: auto;
  height: auto;
  border-width: 0px;
}
</style>
